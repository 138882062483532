import React, { useContext } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import Autocomplete from './Autocomplete';
import { SelectedPlaceMarker } from './SelectedPlaceMarker';
import { AppContext } from './Context';
import { ReviewMarkers } from './ReviewMarkers';
import { useAppSettings } from './hooks/useAppSettings';
import { WishListMarkers } from './WishListMarkers';
import { UserMarker } from './UserMarker';

function Map({ containerStyle, center, zoom }: any) {
  const { setMap, setSelectedPlace } = useContext(AppContext);

  return (
    <GoogleMap
      onClick={(e) => {
        if ((e as any).placeId && e.latLng) {
          console.log('stopping events');
          const placeId: string = (e as any).placeId;
          setSelectedPlace({ placeId, latLng: e.latLng });
          e.stop();
        }
      }}
      onLoad={(map) => {
        console.log('Setting map', map);
        setMap(map);
      }}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
        gestureHandling: 'greedy',
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
              { visibility: 'off' }, // Hide labels for places of interest
            ],
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.icon',
            stylers: [
              { visibility: 'off' }, // Hide icons for transit stations
            ],
          },
        ],
      }}
    >
      <Autocomplete />
      <SelectedPlaceMarker />
      <ReviewMarkers />
      <WishListMarkers />
      <UserMarker />
    </GoogleMap>
  );
}

export default React.memo(Map);
