import axios from 'axios';
import { useState, useCallback } from 'react';
import { SHEET_BEST_URL } from '../Constants';
import { formatSheetUrlForPatchRequest } from './useUpdateReview';

export const useDeleteReview = (reviewId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const deleteReview = useCallback(() => {
    if (!reviewId) {
      setError('You must pass in a non-empty review id to deleted');
      return Promise.reject();
    }
    console.log('Deleting review', reviewId);
    setError(null);
    setIsLoading(true);

    const sheetsUrl = formatSheetUrlForPatchRequest(SHEET_BEST_URL, reviewId);

    return axios
      .delete(sheetsUrl)
      .then((response) => {
        console.log('Deleted row', response);
      })
      .catch((error) => {
        setError(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reviewId]);

  return {
    isLoading,
    deleteReview,
    error,
  };
};
