import { Marker } from '@react-google-maps/api';
import { useContext, useMemo } from 'react';
import { AppContext } from './Context';
import { useSetSelectedPlace } from './hooks/useSetSelectedPlace';

const getMarkerIconForWishList = () => ({
  url: `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><text x="50%" y="50%" dy=".3em" text-anchor="middle" font-size="16px">🚩</text></svg>`,
  scaledSize: new google.maps.Size(40, 40),
});

export const WishListMarkers = () => {
  const { loadedWishListItems } = useContext(AppContext);
  const setSelectedPlace = useSetSelectedPlace();

  const markers = useMemo(() => {
    return loadedWishListItems.map((item) => (
      <Marker
        key={item.id}
        position={new google.maps.LatLng(item.lat, item.lng)}
        onClick={() =>
          setSelectedPlace({
            placeId: item.placeId,
            latLng: new google.maps.LatLng(item.lat, item.lng),
          })
        }
        icon={getMarkerIconForWishList()}
      ></Marker>
    ));
  }, [loadedWishListItems]);

  return <>{markers}</>;
};
