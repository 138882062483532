import { OverlayView, useGoogleMap } from '@react-google-maps/api';
import axios from 'axios';

import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Container,
  Rating,
  Image,
  Segment,
} from 'semantic-ui-react';
import { AddReviewForm } from './AddReviewForm';
import { MAP_API_KEY } from './Constants';
import { AppContext } from './Context';
import { useCreateWishListItem } from './hooks/useCreateWishListItem';
import { useDeleteWishListItem } from './hooks/useDeleteWishListItem';
import { usePlacesQuery } from './hooks/usePlacesQuery';
import { usePlacesService } from './hooks/usePlacesService';
import { ImageCarousel } from './ImageCarousel';
import { ReviewDisplay } from './ReviewDisplay';

export const SelectedPlaceOverlay = () => {
  const {
    selectedPlace,
    setSelectedPlace,
    loadedReviews,
    loadedWishListItems,
    wishListItemForPlace,
    reloadWishListItems,
    authorized,
  } = useContext(AppContext);
  const placesService = usePlacesService();
  const { isLoading: isCreatingWishListItem, createWishListItem } =
    useCreateWishListItem();
  const { isDeleting, deleteWishListItem } = useDeleteWishListItem();
  useEffect(() => {
    // try to resolve just the placeId to a placeResult
    if (selectedPlace && !selectedPlace.place) {
      console.log('getting places details', selectedPlace, placesService);
      placesService?.getDetails(
        {
          placeId: selectedPlace.placeId,
          fields: [
            'name',
            'formatted_address',
            'geometry',
            'rating',
            'price_level',
          ],
        },
        (result, status) => {
          console.log('Place service response', status, result);
          if (status === google.maps.places.PlacesServiceStatus.OK && result) {
            setSelectedPlace({ ...selectedPlace, place: result });
          }
        },
      );
    }
  }, [selectedPlace, placesService]);

  if (!selectedPlace) {
    return null;
  }

  const reviewForPlace = loadedReviews.find(
    (review) => review.placeId === selectedPlace.placeId,
  );

  const placeResult = selectedPlace.place;
  return (
    <div style={{ position: 'absolute', bottom: '8px' }}>
      <Container>
        <Card>
          {placeResult &&
            placeResult.photos &&
            placeResult.photos.length > 0 && (
              <ImageCarousel
                photoUrls={placeResult.photos.map((photo) =>
                  photo.getUrl({
                    maxWidth: 350,
                    maxHeight: 250,
                  }),
                )}
              />
            )}
          <Card.Content textAlign="left">
            <Card.Header>
              {wishListItemForPlace ? '🚩' : ''}
              {selectedPlace.place?.name || 'Loading...'}
              <div style={{ float: 'right' }}>
                <Button
                  basic
                  circular
                  icon="flag"
                  color={wishListItemForPlace ? 'red' : 'green'}
                  size="mini"
                  loading={isDeleting || isCreatingWishListItem}
                  disabled={
                    !authorized ||
                    isCreatingWishListItem ||
                    !Boolean(selectedPlace.place?.name)
                  }
                  onClick={() => {
                    if (!wishListItemForPlace) {
                      createWishListItem({
                        placeId: selectedPlace.placeId,
                        restaurantName: selectedPlace.place?.name || '',
                        notes: '',
                        lat: selectedPlace.latLng.lat(),
                        lng: selectedPlace.latLng.lng(),
                      })?.then(() => {
                        setTimeout(() => reloadWishListItems(), 150);
                      });
                    } else {
                      deleteWishListItem(wishListItemForPlace.id)?.then(() => {
                        setTimeout(() => reloadWishListItems(), 150);
                      });
                    }
                  }}
                />
                <Button
                  basic
                  circular
                  icon="close"
                  size="mini"
                  onClick={() => setSelectedPlace(null)}
                />
              </div>
            </Card.Header>
            {placeResult && (
              <>
                <Card.Meta>{placeResult.formatted_address}</Card.Meta>
                <Card.Meta>{placeResult.formatted_phone_number}</Card.Meta>
                <Card.Meta>
                  <Rating
                    icon="star"
                    maxRating={5}
                    defaultRating={placeResult.rating || 2}
                  />
                  ({placeResult.rating})
                </Card.Meta>
              </>
            )}
          </Card.Content>
          <Card.Content>
            {reviewForPlace && (
              <ReviewDisplay review={reviewForPlace} key={reviewForPlace.id} />
            )}
            {!reviewForPlace && authorized && <AddReviewForm />}
          </Card.Content>
        </Card>
      </Container>
    </div>
  );
};
