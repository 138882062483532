import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { SHEET_BEST_URL } from '../Constants';
import { WishListItem } from '../Context';

export const useLoadWishListItems = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [wishListItems, setWishListItems] = useState<WishListItem[]>([]);

  const fetchItems = useCallback(() => {
    setIsLoading(true);
    try {
      axios
        .get(SHEET_BEST_URL + '/tabs/WishList')
        .then((response) => {
          console.log('wishlist response', response.data);
          setWishListItems(response.data);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    fetchItems();
  }, []);

  return {
    wishListItems,
    isLoading,
    loadWishListItems: fetchItems,
  };
};
