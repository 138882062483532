import { Marker } from '@react-google-maps/api';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { AppContext, StoredReview } from './Context';
import { AppSettings, useAppSettings } from './hooks/useAppSettings';
import { useReviewFilters } from './hooks/useReviewFilters';
import { useSetSelectedPlace } from './hooks/useSetSelectedPlace';
import { ReviewMarker } from './ReviewMarker';

export const ReviewMarkers = () => {
  const { filteredReviews } = useReviewFilters();
  const setSelectedPlace = useSetSelectedPlace();

  const filteredMarkers = useMemo(() => {
    console.log('re-rendering all markers');
    return filteredReviews.map((review) => (
      <ReviewMarker
        review={review}
        key={review.id}
        onClick={() =>
          setSelectedPlace({
            placeId: review.placeId,
            latLng: new google.maps.LatLng(review.lat, review.lng),
          })
        }
      />
    ));
  }, [filteredReviews, setSelectedPlace]);

  return <>{filteredMarkers}</>;
};
