import { useCallback } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useSetSelectedPlace } from './hooks/useSetSelectedPlace';
import { useUserLocation } from './hooks/useUserLocation';

const baseProps = {
  circular: true,
  size: 'small' as const,
  icon: 'location arrow' as const,
};
export const UserLocationButton = () => {
  const { userLocation, disconnected, geolocationEnabled } = useUserLocation();
  const setSelectedPlace = useSetSelectedPlace();
  const handleOnClick = useCallback(() => {
    if (userLocation) {
      setSelectedPlace({
        latLng: new google.maps.LatLng(
          userLocation.latitude,
          userLocation.longitude,
        ),
      });
    }
  }, [userLocation]);
  if (!geolocationEnabled) {
    return <Button {...baseProps} disabled color="grey" />;
  }
  return (
    <Button
      {...baseProps}
      disabled={disconnected}
      color={disconnected ? 'red' : 'blue'}
      onClick={handleOnClick}
    />
  );
};
