import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Modal, Message, Divider } from 'semantic-ui-react';
import { AppContext } from './Context';

function generateFakeIP() {
  const getRandomSegment = () => Math.floor(Math.random() * 256);
  const ipSegments = Array.from({ length: 4 }, getRandomSegment);
  const fakeIP = ipSegments.join('.');
  return fakeIP;
}

const fakeIp = generateFakeIP();
const WELCOME_MODAL_SEEN_KEY = 'WELCOME_MODAL_SEEN_KEY';

export const WelcomeModal = () => {
  const { verifyPassphrase, verifyingPassphrase, authorized } =
    useContext(AppContext);
  const [passPhase, setPassPhrase] = useState('');
  const [showModal, setShowModal] = useState(
    !Boolean(localStorage.getItem(WELCOME_MODAL_SEEN_KEY)) ||
      Boolean(new URLSearchParams(window.location.search).get('showWelcome')) ||
      !authorized,
  );

  useEffect(() => {
    if (authorized) {
      setShowModal(false);
    }
  }, [authorized]);

  const handleOnClose = useCallback(() => {
    if (passPhase) {
      verifyPassphrase(passPhase);
    }
    localStorage.setItem(WELCOME_MODAL_SEEN_KEY, WELCOME_MODAL_SEEN_KEY);
  }, [passPhase]);

  return (
    <Modal
      open={showModal}
      dimmer="blurring"
      onClose={handleOnClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>❤️ Welcome to J&J Reviews!</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div>
            This website was launched on May 28, 2023 in order to commerate
            Jessica Ho's 27th birthday. It is intended to provide a place to
            catalog all of the reviews of the various restaurants at which Jess
            and Justin have dined. The author of this web page would like to
            extend the happiest of birthdays to Jess, and I hope she finds this
            page satisfactory.
          </div>
        </Modal.Description>
        <Divider />
        <Modal.Description>
          If you are Jess or Justin, please fill in the answer to the following
          question to gain write access to this app. Otherwise you may explore
          this map in read-only mode.
          <div className="code-block">
            Looks like we've got ourselves a couple of:{' '}
            <input
              type="text"
              value={passPhase}
              onChange={(e) => setPassPhrase(e.target.value)}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="grey"
          content="View app in read-only mode"
          onClick={() => setShowModal(false)}
        />
        <Button
          color="green"
          disabled={!passPhase || verifyingPassphrase}
          loading={verifyingPassphrase}
          content="Verify I am Jess or Justin."
          onClick={handleOnClose}
        />
      </Modal.Actions>
    </Modal>
  );
};
