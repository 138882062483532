import { useState } from 'react';
import { Button, Icon, Image } from 'semantic-ui-react';

export const ImageCarousel = ({ photoUrls }: { photoUrls: string[] }) => {
  const [index, setPhotoIndex] = useState(0);

  return (
    <div style={{ position: 'relative' }}>
      <Image src={photoUrls[index]} />
      <div style={{ position: 'absolute', bottom: '8px', left: '8px' }}>
        <Button
          icon="arrow left"
          size="mini"
          disabled={photoUrls.length === 0 || index === 0}
          onClick={() => {
            setPhotoIndex((index) => index - 1);
          }}
        />
      </div>
      <div style={{ position: 'absolute', bottom: '8px', right: '8px' }}>
        <Button
          icon="arrow right"
          size="mini"
          disabled={photoUrls.length === 0 || index === photoUrls.length - 1}
          onClick={() => {
            setPhotoIndex((index) => index + 1);
          }}
        />
      </div>
    </div>
  );
};
