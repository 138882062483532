import { useCallback, useEffect, useState } from 'react';
import { SHEET_BEST_URL } from '../Constants';
import axios from 'axios';
import { StoredReview } from '../Context';

export const useLoadReviews = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [sheetData, setSheetData] = useState<Array<StoredReview>>([]);

  const loadReviews = useCallback(() => {
    console.log('Fetching reviews');
    setIsLoading(true);
    axios
      .get(SHEET_BEST_URL)
      .then((response) => {
        console.log('received data:', response);
        setSheetData(
          response.data
            .filter((datum: any) => datum.id)
            .map((datum: any) => ({
              ...datum,
              lat: Number(datum.lat),
              lng: Number(datum.lng),
              restaurantName: datum.restaurantName || '',
              notes: datum.notes || '',
            })),
        );
      })
      .catch((error) => {
        console.error(error);
        alert(
          'Something went wrong fetching the review data. Try reloading...',
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    loadReviews();
  }, []);

  return { isLoading, reviews: sheetData, loadReviews };
};
