import axios from 'axios';
import { useCallback, useState } from 'react';
import { SHEET_BEST_URL } from '../Constants';
import { CreateReviewData } from './useCreateReview';

export type UpdateReviewData = Partial<CreateReviewData>;

export const formatSheetUrlForPatchRequest = (
  sheetUrl: string,
  reviewId: string
) => `${sheetUrl}/id/*${reviewId}*`;

export const useUpdateReview = (reviewId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const updateReview = useCallback(
    (data: UpdateReviewData) => {
      if (!reviewId) {
        setError('You must pass in a non-empty review id to update');
        return Promise.reject();
      }
      console.log('Updating review', reviewId, data);
      setError(null);
      setIsLoading(true);

      const sheetsUrl = formatSheetUrlForPatchRequest(SHEET_BEST_URL, reviewId);

      return axios
        .patch(sheetsUrl, data)
        .then((response) => {
          console.log('Updated row', response);
        })
        .catch((error) => {
          setError(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [reviewId]
  );

  return {
    isLoading,
    updateReview,
    error,
  };
};
