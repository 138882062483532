import { useContext, useMemo } from 'react';
import { AppContext } from '../Context';

export const usePlacesService = () => {
  const { map } = useContext(AppContext);
  return useMemo(() => {
    if (!map) {
      return null;
    }
    return new google.maps.places.PlacesService(map);
  }, [map]);
};
