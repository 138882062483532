import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { PASSPHRASE_SHEET_URL } from '../Constants';

const PREVIOUSLY_SUBMITTED_PASSPHRASE_KEY = 'previously_submitted_passphrase';

const formatPassPhraseUrl = (base: string, passphrase: string) =>
  `${base}/passphrase/${passphrase}`;
export const usePassPhraseVerification = () => {
  const [authorized, setAuthorized] = useState(false);
  const [verifyingPassphrase, setVerifyingPassphrase] = useState(false);

  const verifyPassphrase = useCallback((passphrase: string) => {
    console.log('Attempting to verify passphrase', passphrase);
    setVerifyingPassphrase(true);
    try {
      axios
        .get(
          formatPassPhraseUrl(PASSPHRASE_SHEET_URL, passphrase.toLowerCase()),
        )
        .then((response) => {
          console.log('received response', response);
          if (response.data.length > 0) {
            setAuthorized(true);
            localStorage.setItem(
              PREVIOUSLY_SUBMITTED_PASSPHRASE_KEY,
              btoa(passphrase),
            );
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setVerifyingPassphrase(false);
        });
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    const previousPassphrase = localStorage.getItem(
      PREVIOUSLY_SUBMITTED_PASSPHRASE_KEY,
    );
    if (previousPassphrase) {
      verifyPassphrase(atob(previousPassphrase));
    }
  }, []);

  return { authorized, verifyPassphrase, verifyingPassphrase };
};
