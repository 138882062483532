import axios from 'axios';
import { useCallback, useState } from 'react';
import { WISHLIST_SHEET_URL } from '../Constants';

const formatUrlForWishListItem = (id: string) =>
  `${WISHLIST_SHEET_URL}/id/*${id}*`;

export const useDeleteWishListItem = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteWishListItem = useCallback(
    (id: string) => {
      console.log('Deleting wish list item');
      setIsDeleting(true);
      try {
        return axios
          .delete(formatUrlForWishListItem(id))
          .then(() => {
            console.log('Delete wishlist item');
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setIsDeleting(false);
          });
      } catch (err) {
        console.error(err);
      }
    },
    [setIsDeleting],
  );

  return { isDeleting, deleteWishListItem };
};
