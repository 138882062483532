import axios from 'axios';
import { useCallback, useState, useContext } from 'react';
import { SHEET_BEST_URL } from '../Constants';
import { AppContext, StoredReview } from '../Context';

export type CreateReviewData = Pick<
  StoredReview,
  | 'jessReview'
  | 'justinReview'
  | 'notes'
  | 'placeId'
  | 'lat'
  | 'lng'
  | 'restaurantName'
>;

export const useCreateReview = () => {
  const { reloadReviews } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const createReview = useCallback((data: CreateReviewData) => {
    const isoTimestamp = new Date().toISOString();
    console.log('creating review', data);
    setError(null);
    setIsLoading(true);

    axios
      .post(SHEET_BEST_URL, {
        ...data,
        created: isoTimestamp,
        updated: isoTimestamp,
        id: btoa(isoTimestamp),
      })
      .then((response) => {
        console.log('post data:', response);
        reloadReviews();
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { createReview, isLoading, error };
};
