import { useContext, useEffect, useLayoutEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import { AppContext } from './Context';
import { useUpdateReview } from './hooks/useUpdateReview';
import Map from './Map';
import { Image } from 'semantic-ui-react';
import { SelectedPlaceOverlay } from './SelectedPlaceOverlay';
import { WelcomeModal } from './WelcomeModal';
import { SettingsButton } from './SettingsButton';
import { TableViewButton } from './TableViewButton';
import { UserLocationButton } from './UserLocationButton';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 40.7831,
  lng: -73.971321,
};

function App() {
  const { selectedPlace } = useContext(AppContext);
  // ios safari has an annoying issue with the address bar
  // taking up part of the height, so we can't use 100vh.
  useEffect(() => {
    const calculateHeight = () => {
      const root = document.getElementById('app-root');
      if (root) {
        root.style.height = window.innerHeight + 'px';
        root.style.width = window.innerWidth + 'px';
      }
    };
    window.addEventListener('resize', calculateHeight);
    calculateHeight();
  }, []);

  return (
    <div
      id="app-root"
      className="App"
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      <Map containerStyle={containerStyle} center={center} zoom={12}></Map>
      <SelectedPlaceOverlay />
      <WelcomeModal />
      <div style={{ position: 'absolute', top: '12px', left: '32px' }}>
        <Image src="/jlo.jpeg" size="mini" circular bordered />
      </div>
      <div style={{ position: 'absolute', top: '12px', left: '8px' }}>
        <Image src="/jho.jpeg" size="mini" circular bordered />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '12px',
          left: '8px',
          visibility: !selectedPlace ? 'visible' : 'hidden',
        }}
      >
        <SettingsButton />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '12px',
          left: '48px',
          visibility: !selectedPlace ? 'visible' : 'hidden',
        }}
      >
        <TableViewButton />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '12px',
          left: '88px',
          visibility: !selectedPlace ? 'visible' : 'hidden',
        }}
      >
        <UserLocationButton />
      </div>
    </div>
  );
}

export default App;
