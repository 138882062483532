import { useContext, useEffect, useState } from 'react';
import { Button, Modal, Tab } from 'semantic-ui-react';
import { AppContext } from './Context';
import { usePrevious } from './hooks/usePrevious';
import { ReviewTable } from './ReviewTable';
import { WishListTable } from './WishListTable';

const panes = [
  {
    menuItem: 'Reviews',
    pane: <ReviewTable />,
  },
  {
    menuItem: 'Wish list',
    pane: <WishListTable />,
  },
];

export const TableViewButton = () => {
  // for managing the active tab
  const { selectedPlace } = useContext(AppContext);
  const previousSelectedPlace = usePrevious(selectedPlace);
  const [activeIndex, setActiveIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  // This effect block is to implement the product behavior
  // where clicking a name in the table view will close the modal.
  useEffect(() => {
    if (previousSelectedPlace !== selectedPlace) {
      setModalOpen(false);
    }
  }, [previousSelectedPlace, selectedPlace]);
  return (
    <div>
      <Button
        icon="table"
        onClick={() => setModalOpen(true)}
        circular
        size="small"
        color="blue"
      />
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>Table View</Modal.Header>
        <Modal.Content scrolling>
          <Tab
            menu={{ secondary: true, pointing: true, color: 'blue' }}
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={(e, { activeIndex }) =>
              setActiveIndex(activeIndex as any)
            }
            renderActiveOnly={false}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};
