import { useCallback } from 'react';
import { useContext, useMemo } from 'react';
import {
  AppContext,
  BOLT,
  NO_GO,
  ReviewFilter,
  REVIEW_VALUES,
  STAR,
  StoredReview,
} from '../Context';

export const doesReviewMatchFilter = (
  review: StoredReview,
  reviewFilter: ReviewFilter,
) => {
  const reviewOperator = reviewFilter.operator || 'AND';
  const operator: (a: boolean, b: boolean) => boolean =
    reviewOperator === 'AND'
      ? (jessValue, justinValue) => jessValue && justinValue
      : (jessValue, justinValue) => jessValue || justinValue;

  return operator(
    review.jessReview === reviewFilter.jessReview,
    review.justinReview === reviewFilter.justinReview,
  );
};

export const useReviewFilters = () => {
  const { activeFilters, setActiveFilters, loadedReviews } =
    useContext(AppContext);
  const filteredReviews = useMemo(
    () =>
      loadedReviews.filter((review) => {
        if (activeFilters.length === 0) {
          return true;
        }
        return activeFilters.some((filter) =>
          doesReviewMatchFilter(review, filter),
        );
      }),
    [activeFilters, loadedReviews],
  );

  const hasFilter = useCallback(
    (reviewFilter: ReviewFilter) => {
      const result = activeFilters.some(
        (filter) =>
          filter.jessReview === reviewFilter.jessReview &&
          filter.justinReview === reviewFilter.justinReview,
      );
      return result;
    },
    [activeFilters],
  );

  const toggleFilter = useCallback(
    (reviewFilter: ReviewFilter) => {
      if (hasFilter(reviewFilter)) {
        setActiveFilters(
          activeFilters.filter(
            (filter) =>
              !(
                filter.jessReview === reviewFilter.jessReview &&
                filter.justinReview === reviewFilter.justinReview &&
                filter.operator === reviewFilter.operator
              ),
          ),
        );
      } else {
        setActiveFilters(activeFilters.concat([reviewFilter]));
      }
    },
    [activeFilters, setActiveFilters],
  );
  return {
    activeFilters,
    setActiveFilters,
    hasFilter,
    toggleFilter,
    filteredReviews,
  };
};
