import { useCallback, useContext, useState } from 'react';
import { Button, Container, Form } from 'semantic-ui-react';
import { AppContext, EMPTY, REVIEW_VALUES, WishListItem } from './Context';
import { CreateReviewData, useCreateReview } from './hooks/useCreateReview';
import { useDeleteWishListItem } from './hooks/useDeleteWishListItem';
import { OPTIONS } from './ReviewDisplay';
import { FormValues, ReviewForm } from './ReviewForm';

type Props = {};

export const AddReviewForm = () => {
  const { selectedPlace, wishListItemForPlace, authorized } =
    useContext(AppContext);

  const { createReview, isLoading } = useCreateReview();
  const { deleteWishListItem, isDeleting } = useDeleteWishListItem();
  const [formValues, setFormValues] = useState<FormValues>();
  const [jessReview, setJessReview] = useState<REVIEW_VALUES>('');
  const [justinReview, setJustinReview] = useState<REVIEW_VALUES>('');
  const [notes, setNotes] = useState('');
  const handleCreateOnClick = useCallback(
    (data: CreateReviewData, wishListItem: WishListItem | null) => {
      createReview(data);
    },
    [createReview, deleteWishListItem],
  );

  if (!selectedPlace) {
    return null;
  }

  const { place, placeId } = selectedPlace;

  if (!place) {
    return null;
  }

  const name = place.name;
  const location = place.geometry?.location;
  const lat = location?.lat();
  const lng = location?.lng();
  if (!name || !lat || !lng) {
    return null;
  }
  return (
    <div>
      <ReviewForm
        initialFormValues={{
          jessReview: EMPTY,
          justinReview: EMPTY,
          notes: '',
        }}
        disabled={!authorized}
        onChange={(changedValue, previousValues, newFormValues) => {
          setFormValues(newFormValues);
        }}
      />
      <div style={{ textAlign: 'right', paddingTop: '8px' }}>
        <Button
          type="submit"
          primary
          disabled={!formValues || isLoading || !authorized}
          onClick={() => {
            if (formValues) {
              handleCreateOnClick(
                {
                  jessReview: formValues.jessReview,
                  justinReview: formValues.justinReview,
                  notes: formValues.notes,
                  placeId,
                  lat,
                  lng,
                  restaurantName: name,
                },
                wishListItemForPlace,
              );
            }
          }}
        >
          Add Review
        </Button>
      </div>
    </div>
  );
};
