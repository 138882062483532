import { useContext, useState, useEffect } from 'react';
import { Table, Input } from 'semantic-ui-react';
import { AppContext } from './Context';
import { useSetSelectedPlace } from './hooks/useSetSelectedPlace';

export const WishListTable = ({ active }: { active?: boolean }) => {
  const { loadedWishListItems } = useContext(AppContext);
  const [filteredWishList, setFilteredWishList] = useState(loadedWishListItems);
  const [nameFilter, setNameFilter] = useState('');
  const setSelectedPlace = useSetSelectedPlace();
  const [timer, setTimer] = useState<any>(null);

  useEffect(() => {
    if (timer) {
      console.log('Clearing existing timer');
      clearTimeout(timer);
    }

    const debounceTimer = setTimeout(() => {
      console.log('Executing timer');
      setFilteredWishList(
        loadedWishListItems.filter((item) =>
          item.restaurantName.toLowerCase().includes(nameFilter.toLowerCase()),
        ),
      );
    }, 100);

    setTimer(debounceTimer);

    return () => clearTimeout(debounceTimer);
  }, [loadedWishListItems, nameFilter]);

  if (!active) {
    return null;
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Restaurant</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Input
              type="text"
              value={nameFilter}
              onChange={(e, data) => setNameFilter(data.value as any)}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {filteredWishList.map((item) => (
          <Table.Row key={item.id}>
            <Table.Cell>
              <a
                href="#"
                onClick={() =>
                  setSelectedPlace({
                    placeId: item.placeId,
                    latLng: new google.maps.LatLng(item.lat, item.lng),
                  })
                }
              >
                {item.restaurantName}
              </a>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
