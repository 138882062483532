import { useEffect, useRef } from 'react';

export const usePrevious = <T,>(value: T): T | undefined => {
  const previousValueRef = useRef<T>();

  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);

  return previousValueRef.current;
};
