import { Marker } from '@react-google-maps/api';
import { useContext } from 'react';
import { AppContext } from './Context';
import { ReviewMarker } from './ReviewMarker';

export const SelectedPlaceMarker = () => {
  const { selectedPlace, reviewForSelectedPlace } = useContext(AppContext);
  if (!selectedPlace || reviewForSelectedPlace) {
    return null;
  }

  return <Marker position={selectedPlace.latLng} />;
};
