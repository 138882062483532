import axios from 'axios';
import { useCallback, useState } from 'react';
import { WISHLIST_SHEET_URL } from '../Constants';
import { WishListItem } from '../Context';

export type CreateWishListItemData = Pick<
  WishListItem,
  'notes' | 'placeId' | 'lat' | 'lng' | 'restaurantName'
>;
export const useCreateWishListItem = () => {
  const [isLoading, setIsLoading] = useState(false);

  const createWishListItem = useCallback((data: CreateWishListItemData) => {
    console.log('Creating wishlist item', data);
    setIsLoading(true);
    try {
      const isoTimestamp = new Date().toISOString();
      return axios
        .post(WISHLIST_SHEET_URL, {
          ...data,
          created: isoTimestamp,
          updated: isoTimestamp,
          id: btoa(isoTimestamp),
        })
        .then((response) => {
          console.log('Wishlist create response', response);
          return response.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  }, []);

  return {
    isLoading,
    createWishListItem,
  };
};
