import { Marker } from '@react-google-maps/api';
import { useContext } from 'react';
import {
  AppContext,
  BOLT,
  EMPTY,
  NO_GO,
  REVIEW_VALUES,
  STAR,
  StoredReview,
} from './Context';
import { AppSettings, useAppSettings } from './hooks/useAppSettings';

export const getIconForReviewValue = (value: REVIEW_VALUES) => {
  if (value === STAR) {
    return '⭐️';
  }

  if (value === BOLT) {
    return '⚡️';
  }

  if (value === NO_GO) {
    return '🙅🏻‍♀️';
  }

  return '🤷🏻‍♂️';
};

const getIconForReview = (
  { justinReview, jessReview }: StoredReview,
  {
    renderSettings: {
      doubleStarEmoji,
      singleStarEmoji,
      singleBoltEmoji,
      singleNoGoEmoji,
      emptyEmoji,
    },
  }: AppSettings,
): string => {
  if (justinReview === STAR && jessReview === STAR) {
    return doubleStarEmoji;
  }

  if (justinReview === STAR || jessReview === STAR) {
    return singleStarEmoji;
  }

  if (justinReview === BOLT || jessReview === BOLT) {
    return singleBoltEmoji;
  }

  if (justinReview === NO_GO || jessReview === NO_GO) {
    return singleNoGoEmoji;
  }

  return emptyEmoji;
};

const getMarkerIconForReview = (
  review: StoredReview,
  appSettings: AppSettings,
) => ({
  url: `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><text x="50%" y="50%" dy=".3em" text-anchor="middle" font-size="16px">${getIconForReview(
    review,
    appSettings,
  )}</text></svg>`,
  scaledSize: new google.maps.Size(40, 40),
});

type Props = {
  review: StoredReview;
  onClick?: () => void;
};
export const ReviewMarker = ({ review, onClick }: Props) => {
  const { appSettings } = useContext(AppContext);

  return (
    <Marker
      position={{ lat: review.lat, lng: review.lng }}
      key={review.id}
      icon={getMarkerIconForReview(review, appSettings)}
      onClick={onClick}
    />
  );
};
