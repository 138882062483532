import { Marker } from '@react-google-maps/api';
import { useUserLocation } from './hooks/useUserLocation';

export const UserMarker = () => {
  const { userLocation } = useUserLocation();

  if (!userLocation) {
    return null;
  }

  return (
    <Marker
      position={
        new google.maps.LatLng(userLocation.latitude, userLocation.longitude)
      }
      icon={{
        url: `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><text x="50%" y="50%" dy=".3em" text-anchor="middle" font-size="16px">🔵</text></svg>`,
        scaledSize: new google.maps.Size(20, 20),
      }}
    />
  );
};
