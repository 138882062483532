const MAP_API_KEY = 'AIzaSyC5EAw5tZuG8XbHEJ3xHWsxwyVenSFCLd0' as const;
const SHEET_BEST_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://sheet.best/api/sheets/ff25b9cd-4361-4ffd-a9d1-ac39fdb99954'
    : 'https://sheet.best/api/sheets/2326fc28-8cbf-4e70-9093-cf0537efac7d';

const WISHLIST_SHEET_URL = `${SHEET_BEST_URL}/tabs/WishList`;
const PASSPHRASE_SHEET_URL = `${SHEET_BEST_URL}/tabs/Passphrases`;
export {
  MAP_API_KEY,
  SHEET_BEST_URL,
  WISHLIST_SHEET_URL,
  PASSPHRASE_SHEET_URL,
};
