import { useGoogleMap } from '@react-google-maps/api';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext, SelectedPlaceValues } from '../Context';

/*
This hook is a wrapper around the setSelectedPlace context setter.
This hook sets the global place but also centralizes logic for
ensuring the map actually zooms and pans to where it should.
*/
export const useSetSelectedPlace = () => {
  const {
    setSelectedPlace: _setSelectedPlace,
    appSettings,
    map,
  } = useContext(AppContext);
  const [latLngToPanTo, setLatLngToPanTo] = useState<google.maps.LatLng | null>(
    null,
  );
  const setSelectedPlace = useCallback(
    (place: SelectedPlaceValues | { latLng: google.maps.LatLng } | null) => {
      if (place) {
        if ('placeId' in place) {
          _setSelectedPlace(place);
        }
        setLatLngToPanTo(place.latLng);
      } else {
        _setSelectedPlace(null);
      }
    },
    [appSettings.zoomOnReviewClick],
  );

  useEffect(() => {
    if (!latLngToPanTo || !map) {
      return;
    }

    if (appSettings.zoomOnReviewClick) {
      map.setZoom(15);
    }
    map.panTo(latLngToPanTo);
  }, [latLngToPanTo, map]);

  return setSelectedPlace;
};
