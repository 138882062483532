import { AppContext, BOLT, NO_GO, STAR, StoredReview } from './Context';
import { UpdateReviewData, useUpdateReview } from './hooks/useUpdateReview';
import {
  Button,
  Container,
  Dimmer,
  Form,
  Loader,
  Select,
} from 'semantic-ui-react';
import { useCallback, useState, useContext } from 'react';
import debounce from 'lodash/debounce';
import { useDeleteReview } from './hooks/useDeleteReview';
import { getIconForReviewValue } from './ReviewMarker';
import { ReviewForm } from './ReviewForm';

type Props = {
  review: StoredReview;
};

export const OPTIONS = [
  { key: '1', text: '⭐️', value: STAR },
  { key: '2', text: '⚡️', value: BOLT },
  { key: '3', text: '🙅🏻‍♀️', value: NO_GO },
];

export const ReviewDisplay = ({ review }: Props) => {
  const { authorized, setSelectedPlace, reloadReviews } =
    useContext(AppContext);
  const { deleteReview, isLoading: isDeleting } = useDeleteReview(review.id);
  const { updateReview, isLoading: isUpdating } = useUpdateReview(review.id);
  const debouncedOnChange = useCallback(
    debounce((notes: string) => handleOnUpdate({ notes }), 750),
    [],
  );

  const handleOnUpdate = useCallback((data: UpdateReviewData) => {
    updateReview(data).then(() => {
      // Hack city
      setTimeout(() => reloadReviews(), 500);
    });
  }, []);

  const handleOnDelete = useCallback(() => {
    deleteReview().then(() => {
      setSelectedPlace(null);
      // HACK CITY: It seems like there isn't a read-your-writes
      // guarantee from sheets.best, so we need to give it a bit more time
      // to marinate
      setTimeout(() => reloadReviews(), 500);
    });
  }, []);

  return (
    <>
      <ReviewForm
        initialFormValues={{
          justinReview: review.justinReview,
          jessReview: review.jessReview,
          notes: review.notes,
        }}
        disabled={!authorized || isUpdating || isDeleting}
        onChange={(changedValue, formValues) => {
          if ('jessReview' in changedValue) {
            handleOnUpdate(changedValue);
          }

          if ('justinReview' in changedValue) {
            handleOnUpdate(changedValue);
          }

          if ('notes' in changedValue) {
            debouncedOnChange(changedValue.notes);
          }

          console.log(changedValue, formValues);
        }}
      />
      <div style={{ textAlign: 'right', paddingTop: '8px' }}>
        <Button
          negative
          icon="trash"
          disabled={isUpdating || isDeleting || !authorized}
          onClick={() => {
            handleOnDelete();
          }}
        >
          Delete Review
        </Button>
      </div>
    </>
  );
};
