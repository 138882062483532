import { useCallback, useState } from 'react';
import {
  Button,
  Container,
  Form,
  Image,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import { BOLT, EMPTY, NO_GO, REVIEW_VALUES, STAR } from './Context';
import { OPTIONS } from './ReviewDisplay';
import { getIconForReviewValue } from './ReviewMarker';

export type FormValues = {
  jessReview: REVIEW_VALUES;
  justinReview: REVIEW_VALUES;
  notes: string;
};

type Props = {
  initialFormValues: FormValues;
  disabled: boolean;
  formValues?: FormValues;
  onChange: (
    changedValue: OnChangeParam,
    previousFormValues: FormValues,
    newFormValues: FormValues,
  ) => void;
};

type OnChangeParam =
  | { jessReview: REVIEW_VALUES }
  | { justinReview: REVIEW_VALUES }
  | { notes: string };
export const ReviewForm = ({
  initialFormValues,
  formValues: formValuesFromProps,
  disabled,
  onChange,
}: Props) => {
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const handleOnChange = useCallback(
    (changedValue: OnChangeParam) => {
      const newFormValues = { ...formValues, ...changedValue };
      setFormValues(newFormValues);
      onChange(changedValue, formValues, newFormValues);
    },
    [formValues, setFormValues],
  );
  return (
    <Form>
      <div style={{ textAlign: 'left' }}>
        <Image src="/jho.jpeg" size="mini" circular bordered />
        <span style={{ paddingLeft: '4px' }}>Jess's Review</span>
      </div>
      <div style={{ textAlign: 'left', paddingTop: '4px' }}>
        <div>
          <ReviewButtonGroup
            disabled={disabled}
            value={formValues.jessReview || EMPTY}
            onChange={(value) => handleOnChange({ jessReview: value })}
          />
        </div>
      </div>
      <div style={{ textAlign: 'left', paddingTop: '16px' }}>
        <Image src="/jlo.jpeg" size="mini" circular bordered />
        <span style={{ paddingLeft: '4px' }}>Justin's Review</span>
      </div>
      <div style={{ textAlign: 'left', paddingTop: '4px' }}>
        <div>
          <ReviewButtonGroup
            disabled={disabled}
            value={formValues.justinReview || EMPTY}
            onChange={(value) => handleOnChange({ justinReview: value })}
          />
        </div>
      </div>
      <div style={{ textAlign: 'left', paddingTop: '16px' }}>
        <label>Notes</label>
      </div>
      <div style={{ textAlign: 'left' }}>
        <TextArea
          value={formValues.notes ?? ''}
          onChange={(e, data) => handleOnChange({ notes: data.value as any })}
        />
      </div>
    </Form>
  );
};

const ReviewButtonGroup = ({
  value,
  disabled,
  onChange,
}: {
  value: REVIEW_VALUES;
  disabled: boolean;
  onChange: (newValue: REVIEW_VALUES) => void;
}) => {
  const commonProps = (selected: boolean) => ({
    circular: true,
    size: 'mini' as const,
    compact: true,
    color: 'blue' as const,
    // color: selected ? ('green' as const) : ('grey' as const),
    basic: selected ? false : true,
    disabled,
  });
  return (
    <>
      <Button {...commonProps(value === EMPTY)} onClick={() => onChange(EMPTY)}>
        {getIconForReviewValue(EMPTY)}
      </Button>
      <Button {...commonProps(value === NO_GO)} onClick={() => onChange(NO_GO)}>
        {getIconForReviewValue(NO_GO)}
      </Button>
      <Button {...commonProps(value === BOLT)} onClick={() => onChange(BOLT)}>
        {getIconForReviewValue(BOLT)}
      </Button>
      <Button {...commonProps(value === STAR)} onClick={() => onChange(STAR)}>
        {getIconForReviewValue(STAR)}
      </Button>
    </>
  );
};
