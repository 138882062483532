import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../Context';

export type RenderSettings = {
  doubleStarEmoji: string;
  singleStarEmoji: string;
  singleBoltEmoji: string;
  singleNoGoEmoji: string;
  emptyEmoji: string;
};

export type AppSettings = {
  renderSettings: RenderSettings;
  zoomOnReviewClick: boolean;
};

export const DEFAULT_APP_SETTINGS: AppSettings = {
  renderSettings: {
    doubleStarEmoji: '💫',
    singleStarEmoji: '⭐️',
    singleBoltEmoji: '⚡️',
    singleNoGoEmoji: '🙅🏻‍♀️',
    emptyEmoji: '🤷🏻‍♂️',
  },
  zoomOnReviewClick: true,
};

const APP_SETTINGS_KEY = 'appSettings';
export const useAppSettings = () => {
  const [appSettings, setAppSettings] =
    useState<AppSettings>(DEFAULT_APP_SETTINGS);

  useEffect(() => {
    const storedSettings = localStorage.getItem(APP_SETTINGS_KEY);
    if (storedSettings) {
      setAppSettings({
        ...DEFAULT_APP_SETTINGS,
        ...JSON.parse(storedSettings),
      });
    }
  }, []);

  const setAppSettingsAndStore = useCallback((newAppSettings: AppSettings) => {
    setAppSettings(newAppSettings);
    localStorage.setItem(APP_SETTINGS_KEY, JSON.stringify(newAppSettings));
  }, []);

  return {
    appSettings,
    setAppSettings: setAppSettingsAndStore,
  };
};
