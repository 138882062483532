import { useContext, useState, useEffect } from 'react';
import { Table, Input, Dropdown } from 'semantic-ui-react';
import {
  AppContext,
  BOLT,
  EMPTY,
  NO_GO,
  REVIEW_VALUES,
  STAR,
  StoredReview,
} from './Context';
import { useSetSelectedPlace } from './hooks/useSetSelectedPlace';
import { getIconForReviewValue } from './ReviewMarker';

type Filters = {
  nameFilter: string;
  jessReviewFilters: REVIEW_VALUES[];
  justinReviewFilters: REVIEW_VALUES[];
  notesFilter: string;
};

const filterReviews = (
  reviews: StoredReview[],
  { nameFilter, jessReviewFilters, justinReviewFilters, notesFilter }: Filters,
) => {
  console.log('Filtering reviews', {
    nameFilter,
    jessReviewFilters,
    justinReviewFilters,
    notesFilter,
  });
  return reviews.filter((review) => {
    const matchesNameFilter = nameFilter
      ? review.restaurantName.toLowerCase().includes(nameFilter.toLowerCase())
      : true;
    const matchesJessFilter =
      jessReviewFilters.length > 0
        ? jessReviewFilters.some(
            (reviewFilter) => review.jessReview === reviewFilter,
          )
        : true;
    const matchesJustinFilter =
      justinReviewFilters.length > 0
        ? justinReviewFilters.some(
            (reviewFilter) => review.justinReview === reviewFilter,
          )
        : true;
    const matchesNotesFilter = notesFilter
      ? review.notes.toLowerCase().includes(notesFilter.toLowerCase())
      : true;
    return (
      matchesNameFilter &&
      matchesJessFilter &&
      matchesJustinFilter &&
      matchesNotesFilter
    );
  }, 100);
};

const options = [
  { key: STAR, text: getIconForReviewValue(STAR), value: STAR },
  { key: BOLT, text: getIconForReviewValue(BOLT), value: BOLT },
  { key: NO_GO, text: getIconForReviewValue(NO_GO), value: NO_GO },
  { key: 'EMPTY', text: getIconForReviewValue(EMPTY), value: EMPTY },
];

export const ReviewTable = ({ active }: { active?: boolean }) => {
  const { loadedReviews } = useContext(AppContext);

  const setSelectedPlace = useSetSelectedPlace();
  const [filteredReviews, setFilteredReviews] = useState(loadedReviews);
  const [nameFilter, setNameFilter] = useState('');
  const [jessReviewFilters, setJessReviewFilters] = useState<
    Array<REVIEW_VALUES>
  >([]);

  const [justinReviewFilters, setJustinReviewFilters] = useState<
    Array<REVIEW_VALUES>
  >([]);
  const [notesFilter, setNotesFilter] = useState('');
  const [timer, setTimer] = useState<any>(null);

  useEffect(() => {
    if (timer) {
      console.log('Clearing existing timer');
      clearTimeout(timer);
    }

    const debounceTimer = setTimeout(() => {
      console.log('Executing timer');
      setFilteredReviews(
        filterReviews(loadedReviews, {
          nameFilter,
          jessReviewFilters,
          justinReviewFilters,
          notesFilter,
        }),
      );
    }, 100);

    setTimer(debounceTimer);

    return () => clearTimeout(debounceTimer);
  }, [
    loadedReviews,
    nameFilter,
    jessReviewFilters,
    justinReviewFilters,
    notesFilter,
  ]);

  if (!active) {
    return null;
  }
  return (
    <Table compact unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Restaurant</Table.HeaderCell>
          <Table.HeaderCell>Jess Rating</Table.HeaderCell>
          <Table.HeaderCell>Justin Rating</Table.HeaderCell>
          <Table.HeaderCell>Notes</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Input
              type="text"
              value={nameFilter}
              onChange={(e, data) => {
                setNameFilter(data.value);
              }}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Dropdown
              fluid
              multiple
              selection
              options={options}
              value={jessReviewFilters}
              onChange={(e, data) => {
                setJessReviewFilters(data.value as REVIEW_VALUES[]);
              }}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Dropdown
              fluid
              multiple
              selection
              options={options}
              value={justinReviewFilters}
              onChange={(e, data) => {
                setJustinReviewFilters(data.value as REVIEW_VALUES[]);
              }}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Input
              type="text"
              value={notesFilter}
              onChange={(e, data) => {
                setNotesFilter(data.value);
              }}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {filteredReviews.map((review) => {
          return (
            <Table.Row key={review.id}>
              <Table.Cell>
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedPlace({
                      placeId: review.placeId,
                      latLng: new google.maps.LatLng(review.lat, review.lng),
                    });
                  }}
                >
                  {review.restaurantName}
                </a>
              </Table.Cell>
              <Table.Cell>
                {getIconForReviewValue(review.jessReview)}
              </Table.Cell>
              <Table.Cell>
                {getIconForReviewValue(review.justinReview)}
              </Table.Cell>
              <Table.Cell>{review.notes}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
