import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './Context';
import { MAP_API_KEY } from './Constants';
import { LoadScript } from '@react-google-maps/api';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const libraries = ['places' as const];
root.render(
  <React.StrictMode>
    <LoadScript googleMapsApiKey={MAP_API_KEY} libraries={libraries}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </LoadScript>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
