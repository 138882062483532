import { createContext, useState } from 'react';
import { MAP_API_KEY } from './Constants';
import {
  AppSettings,
  DEFAULT_APP_SETTINGS,
  useAppSettings,
} from './hooks/useAppSettings';
import { useLoadReviews } from './hooks/useLoadReviews';
import { useLoadWishListItems } from './hooks/useLoadWishListItems';
import { usePassPhraseVerification } from './hooks/usePassPhraseVerification';

export type WishListItem = {
  id: string;
  placeId: string;
  notes: string;
  lat: number;
  lng: number;
  restaurantName: string;
};

export type StoredReview = {
  id: string;
  placeId: string;
  jessReview: REVIEW_VALUES;
  justinReview: REVIEW_VALUES;
  notes: string;
  lat: number;
  lng: number;
  restaurantName: string;
};

export const STAR = 'STAR' as const;
export const BOLT = 'BOLT' as const;
export const NO_GO = 'NO_GO' as const;
export const EMPTY = '' as const;
export type REVIEW_VALUES =
  | typeof STAR
  | typeof BOLT
  | typeof NO_GO
  | typeof EMPTY;

export type FILTER_OPERATOR = 'AND' | 'OR';
export type ReviewFilter = {
  jessReview?: REVIEW_VALUES;
  justinReview?: REVIEW_VALUES;
  operator?: FILTER_OPERATOR;
};

export type SelectedPlaceValues = {
  placeId: string;
  latLng: google.maps.LatLng;
  place?: google.maps.places.PlaceResult;
};

export type AppContextValues = {
  map: google.maps.Map | null;
  setMap: (map: google.maps.Map) => void;
  googleMapsApiKey: string;
  selectedPlace: SelectedPlaceValues | null;
  setSelectedPlace: (place: SelectedPlaceValues | null) => void;
  reviewForSelectedPlace: StoredReview | null;
  reloadReviews: () => void;
  activeFilters: Array<ReviewFilter>;
  setActiveFilters: (filters: Array<ReviewFilter>) => void;
  loadedReviews: Array<StoredReview>;
  loadingReviews: boolean;
  wishListItemForPlace: WishListItem | null;
  loadedWishListItems: Array<WishListItem>;
  reloadWishListItems: () => void;
  appSettings: AppSettings;
  setAppSettings: (appSettings: AppSettings) => void;
  authorized: boolean;
  verifyPassphrase: (passphrase: string) => void;
  verifyingPassphrase: boolean;
};

export const AppContext = createContext<AppContextValues>({
  map: null,
  setMap: () => {},
  selectedPlace: null,
  reloadReviews: () => {},
  reviewForSelectedPlace: null,
  loadedReviews: [],
  loadingReviews: false,
  wishListItemForPlace: null,
  authorized: false,
  verifyPassphrase: () => {},
  verifyingPassphrase: false,
  loadedWishListItems: [],
  reloadWishListItems: () => {},
  activeFilters: [],
  setActiveFilters: () => {},
  setSelectedPlace: () => {},
  googleMapsApiKey: MAP_API_KEY,
  appSettings: DEFAULT_APP_SETTINGS,
  setAppSettings: () => {},
});

export const AppContextProvider = ({ children }: any) => {
  const { authorized, verifyPassphrase, verifyingPassphrase } =
    usePassPhraseVerification();
  const { isLoading, reviews, loadReviews: reloadReviews } = useLoadReviews();
  const { wishListItems, loadWishListItems: reloadWishListItems } =
    useLoadWishListItems();
  const { appSettings, setAppSettings } = useAppSettings();

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [filters, setFilters] = useState<Array<ReviewFilter>>([]);

  const [selectedPlace, setSelectedPlace] =
    useState<SelectedPlaceValues | null>(null);

  const reviewForSelectedPlace = selectedPlace
    ? reviews.find((review) => review.placeId === selectedPlace.placeId)
    : null;

  const wishListItemForPlace = selectedPlace
    ? wishListItems.find((item) => item.placeId === selectedPlace.placeId)
    : null;
  return (
    <AppContext.Provider
      value={{
        map: map,
        setMap: setMap,
        selectedPlace,
        activeFilters: filters,
        setActiveFilters: setFilters,
        setSelectedPlace: (place) => {
          setSelectedPlace(place);
        },
        reviewForSelectedPlace: reviewForSelectedPlace || null,
        reloadReviews,
        loadedWishListItems: wishListItems,
        reloadWishListItems,
        wishListItemForPlace: wishListItemForPlace || null,
        loadedReviews: reviews,
        loadingReviews: isLoading,
        googleMapsApiKey: MAP_API_KEY,
        appSettings,
        setAppSettings,
        authorized,
        verifyPassphrase,
        verifyingPassphrase,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
