import { useEffect, useState } from 'react';

export const useUserLocation = () => {
  const [userLocation, setUserLocation] =
    useState<GeolocationCoordinates | null>(null);
  const [disconnected, setDisconnected] = useState(false);
  const [geolocationEnabled, setGeolocatedEnabled] = useState(true);
  useEffect(() => {
    if ('geolocation' in navigator) {
      setGeolocatedEnabled(true);
      navigator.geolocation.watchPosition(
        (position) => {
          setDisconnected(false);
          setUserLocation(position.coords);
        },
        (err) => {
          setDisconnected(true);
        },
      );
    } else {
      setGeolocatedEnabled(false);
    }
  }, []);

  return { userLocation, disconnected, geolocationEnabled };
};
