import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { AppContext } from './Context';
import { AppSettings } from './hooks/useAppSettings';

type RenderSettingsErrors = {
  [k in keyof AppSettings['renderSettings']]: string | null;
};

const emptyErrors: RenderSettingsErrors = {
  doubleStarEmoji: null,
  singleStarEmoji: null,
  singleBoltEmoji: null,
  singleNoGoEmoji: null,
  emptyEmoji: null,
};

export const SettingsButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { appSettings, setAppSettings } = useContext(AppContext);

  const [formAppSettings, _setFormAppSettings] =
    useState<AppSettings>(appSettings);

  const [renderSettingsErrors, setRenderSettingsErrors] =
    useState<RenderSettingsErrors>(emptyErrors);

  // Once the global appSettings changes, set it to the in memory
  // form appSettings copy
  useEffect(() => {
    _setFormAppSettings(appSettings);
  }, [appSettings]);

  const { renderSettings } = formAppSettings;

  const [isDirty, setIsDirty] = useState(false);
  const setFormAppSettings = useCallback((settings: AppSettings) => {
    setIsDirty(true);
    _setFormAppSettings(settings);
  }, []);

  const syncFormToSettings = useCallback(() => {
    setRenderSettingsErrors(emptyErrors);

    const { renderSettings } = formAppSettings;
    const renderErrors = { ...emptyErrors };
    let hasErrors = false;

    [
      'doubleStarEmoji' as const,
      'singleStarEmoji' as const,
      'singleBoltEmoji' as const,
      'singleNoGoEmoji' as const,
      'emptyEmoji' as const,
    ].map((key) => {
      if (!renderSettings[key]) {
        hasErrors = true;
        renderErrors[key] = 'Cannot be empty';
      }
    });

    if (hasErrors) {
      setRenderSettingsErrors(renderErrors);
      return;
    }

    setAppSettings(formAppSettings);
    setIsDirty(false);
  }, [formAppSettings]);

  return (
    <div>
      <Button
        circular
        size="small"
        icon="setting"
        color="grey"
        onClick={() => setModalOpen(true)}
      />
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
        <Modal.Header>Display Settings</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              error={renderSettingsErrors['doubleStarEmoji']}
              inline
              label={'Reviews with two stars:'}
              value={renderSettings.doubleStarEmoji}
              onChange={(e, { name, value }) => {
                setFormAppSettings({
                  ...formAppSettings,
                  renderSettings: {
                    ...renderSettings,
                    doubleStarEmoji: value,
                  },
                });
              }}
            />
            <Form.Input
              inline
              label={'Reviews with at least one star:'}
              error={renderSettingsErrors['singleStarEmoji']}
              value={renderSettings.singleStarEmoji}
              onChange={(e, { name, value }) => {
                setFormAppSettings({
                  ...formAppSettings,
                  renderSettings: {
                    ...renderSettings,
                    singleStarEmoji: value,
                  },
                });
              }}
            />
            <Form.Input
              inline
              label={'Reviews with at least one lightning bolt:'}
              error={renderSettingsErrors['singleBoltEmoji']}
              value={renderSettings.singleBoltEmoji}
              onChange={(e, { name, value }) => {
                setFormAppSettings({
                  ...formAppSettings,
                  renderSettings: {
                    ...renderSettings,
                    singleBoltEmoji: value,
                  },
                });
              }}
            />
            <Form.Input
              inline
              label={'Review with at least one no-go:'}
              error={renderSettingsErrors['singleNoGoEmoji']}
              value={renderSettings.singleNoGoEmoji}
              onChange={(e, { name, value }) => {
                setFormAppSettings({
                  ...formAppSettings,
                  renderSettings: {
                    ...renderSettings,
                    singleNoGoEmoji: value,
                  },
                });
              }}
            />
            <Form.Input
              inline
              label={'Display empty reviews with:'}
              error={renderSettingsErrors['emptyEmoji']}
              value={renderSettings.emptyEmoji}
              onChange={(e, { name, value }) => {
                setFormAppSettings({
                  ...formAppSettings,
                  renderSettings: {
                    ...renderSettings,
                    emptyEmoji: value,
                  },
                });
              }}
            />
            <Form.Checkbox
              label={'Zoom map when panning to review'}
              checked={formAppSettings.zoomOnReviewClick}
              onChange={() =>
                setFormAppSettings({
                  ...formAppSettings,
                  zoomOnReviewClick: !formAppSettings.zoomOnReviewClick,
                })
              }
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            disabled={!isDirty}
            onClick={() => {
              syncFormToSettings();
              setModalOpen(false);
            }}
          >
            Save and close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
