import React, { useCallback, useContext, useRef, useState } from 'react';
import {
  Autocomplete as _Autocomplete,
  useGoogleMap,
} from '@react-google-maps/api';
import { AppContext, BOLT, NO_GO, STAR } from './Context';
import { useSetSelectedPlace } from './hooks/useSetSelectedPlace';
import { Button, Container, Input } from 'semantic-ui-react';
import { useReviewFilters } from './hooks/useReviewFilters';

function Autocomplete() {
  const { selectedPlace } = useContext(AppContext);
  const [autocompleteText, setAutocompleteText] = useState('');
  const { hasFilter, toggleFilter } = useReviewFilters();
  const map = useGoogleMap();
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
  const setSelectedPlace = useSetSelectedPlace();

  const onPlaceChanged = useCallback(() => {
    if (autoCompleteRef.current) {
      const place = autoCompleteRef.current.getPlace();
      if (!place) {
        return;
      }
      const location = place.geometry?.location;

      console.log('panning to place', place, location, map);

      if (place.place_id && location) {
        setSelectedPlace({
          placeId: place.place_id,
          latLng: location,
          place,
        });
      }
    }
  }, []);

  const icon = autocompleteText ? null : { name: 'search' };
  const action = autocompleteText ? (
    <Button
      icon="close"
      onClick={() => {
        setAutocompleteText('');
        if (selectedPlace) {
          setSelectedPlace(null);
        }
      }}
    />
  ) : null;
  const buttonSize = 'mini';

  return (
    <_Autocomplete
      onPlaceChanged={onPlaceChanged}
      onLoad={(autoComplete) => {
        autoCompleteRef.current = autoComplete;
      }}
    >
      <Container>
        <Input
          type="text"
          action={action}
          placeholder="What are ya reviewing, Jess?"
          icon={icon}
          size="large"
          value={autocompleteText}
          onChange={(e, data) => setAutocompleteText(data.value)}
          style={{
            textOverflow: `ellipses`,
            position: 'absolute',
            top: '8px',
            left: '80px',
            right: '8px',
          }}
        />
        <div style={{ position: 'absolute', top: '64px', right: '8px' }}>
          <Button
            size={buttonSize}
            circular
            color="grey"
            positive={hasFilter({ jessReview: STAR, justinReview: STAR })}
            onClick={() => {
              toggleFilter({ jessReview: STAR, justinReview: STAR });
            }}
          >
            ⭐️⭐️
          </Button>
          <Button
            size={buttonSize}
            circular
            color="grey"
            positive={hasFilter({ jessReview: STAR, justinReview: BOLT })}
            onClick={() =>
              toggleFilter({ jessReview: STAR, justinReview: BOLT })
            }
          >
            ⭐️⚡️
          </Button>
          <Button
            size={buttonSize}
            circular
            color="grey"
            positive={hasFilter({ jessReview: BOLT, justinReview: STAR })}
            onClick={() =>
              toggleFilter({ jessReview: BOLT, justinReview: STAR })
            }
          >
            ⚡️⭐️
          </Button>
          <Button
            size={buttonSize}
            circular
            color="grey"
            content="⚡️⚡️"
            positive={hasFilter({ jessReview: BOLT, justinReview: BOLT })}
            onClick={() =>
              toggleFilter({ jessReview: BOLT, justinReview: BOLT })
            }
          />
          <Button
            circular
            color="grey"
            size={buttonSize}
            content="🙅🏻‍♀️"
            positive={hasFilter({
              jessReview: NO_GO,
              justinReview: NO_GO,
              operator: 'OR',
            })}
            onClick={() =>
              toggleFilter({
                jessReview: NO_GO,
                justinReview: NO_GO,
                operator: 'OR',
              })
            }
          />
        </div>
      </Container>
    </_Autocomplete>
  );
}

export default React.memo(Autocomplete);
